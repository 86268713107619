.Top{
  background-color: #FFF;
  &-section{
    height: 100vh;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    position: relative;
    &__image{
      margin: auto 0;
      width: 50%;
      object-fit: cover;
      z-index: 1;
    }
    &__circle1{
      position: absolute;
      height: 70vmin;
      width: 70vmin;
      top: 0;
      bottom: 0;
      margin: auto;
      border-bottom: 1px solid rgb(9, 15, 192);
      border-top: 1px solid rgb(9, 15, 192);
      border-radius: 50%;
      animation: r1 3s linear infinite;
    }
    &__circle2{
      position: absolute;
      height: 85vmin;
      width: 85vmin;
      top: 0;
      bottom: 0;
      margin: auto;
      border-bottom: 2px solid rgb(9, 82, 192);
      border-right: 2px solid rgb(9, 82, 192);
      border-radius: 50%;
      animation: r1 5s linear infinite;
    }
    &__circle3{
      position: absolute;
      height: 98vmin;
      width: 98vmin;
      top: 0;
      bottom: 0;
      margin: auto;
      border-bottom: 2.5px solid rgb(9, 192, 158);
      border-top: 2.5px solid rgb(9, 192, 158);
      border-radius: 50%;
      animation: r1 2s linear infinite;
    }
  }
  @keyframes r1 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
