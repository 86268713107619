.Tech{
  background-color: #FFF;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: -50px;
  margin-bottom: 50px;
  &__title{
    padding: 0 15%;
    text-align: center;
    > p{
      margin: 0 auto 50px auto;
      width: 110px;
      border-bottom: 2px solid;
    }
  }
  &__desc{
    text-align: center;
  }
  &__logo-section{
    width: 100%;
    text-align: center;
    display: flex;
    flex-flow: row-reverse;
    &__tech-img{
      width:50%;
    }
    &-stack{
      width: 50%;
      &__img{
        width: 90%;
        border-radius: 5%;
        background-color: #FFF;
        display: flex;
        justify-content: center;
        padding: 4% 0%;
        >img{
          width: 50%;
          object-fit: cover;
        }
      }
    }
  }
  table{
    text-align: center;
    padding-left: 15%;
  }
}
