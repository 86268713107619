@import url(https://fonts.googleapis.com/css2?family=Glegoo);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Sidenav{font-weight:bold;font-size:18px;position:fixed;width:100%;z-index:90}.Sidenav-container-sp{display:none}@media screen and (max-width: 768px){.Sidenav-container{display:none}.Sidenav-container-sp{display:inline;display:initial;position:fixed;top:-100%;transition-duration:500ms;width:100%;background-color:#f3f3f3}.Sidenav-container-sp__text{border-bottom:1px solid #e8e8e8}.Sidenav-hamburger__button{position:fixed;display:flex;height:50px;width:100%;justify-content:center;align-items:center;background-color:#b1ccda;z-index:91}.Sidenav-hamburger__button>span,.Sidenav-hamburger__button>span:before,.Sidenav-hamburger__button>span:after{content:'';display:block;height:3px;width:50px;border-radius:3px;background-color:#ffffff;position:absolute}.Sidenav-hamburger__button>span:before{bottom:8px}.Sidenav-hamburger__button>span:after{top:8px}}.Sidenav-container__text{padding:10px 10px;cursor:pointer}.Sidenav-container__text:hover{background-color:#c9c9c9}.Sidenav-container-sp__text{padding:10px 10px;cursor:pointer;width:100%}.Sidenav-container-sp__text:hover{background-color:#c9c9c9}#menu-btn-check{display:none}#menu-btn-check:checked ~ .Sidenav-hamburger__button span{background-color:rgba(255,255,255,0)}#menu-btn-check:checked ~ .Sidenav-hamburger__button span::before{bottom:0;transform:rotate(45deg);transition-property:all;transition-duration:500ms}#menu-btn-check:checked ~ .Sidenav-hamburger__button span::after{top:0;transform:rotate(-45deg);transition-property:all;transition-duration:500ms}#menu-btn-check:checked ~ .Sidenav-container-sp{top:50px}

.Top{background-color:#FFF}.Top-section{height:100vh;display:flex;justify-content:center;margin-bottom:50px;position:relative}.Top-section__image{margin:auto 0;width:50%;object-fit:cover;z-index:1}.Top-section__circle1{position:absolute;height:70vmin;width:70vmin;top:0;bottom:0;margin:auto;border-bottom:1px solid #090fc0;border-top:1px solid #090fc0;border-radius:50%;animation:r1 3s linear infinite}.Top-section__circle2{position:absolute;height:85vmin;width:85vmin;top:0;bottom:0;margin:auto;border-bottom:2px solid #0952c0;border-right:2px solid #0952c0;border-radius:50%;animation:r1 5s linear infinite}.Top-section__circle3{position:absolute;height:98vmin;width:98vmin;top:0;bottom:0;margin:auto;border-bottom:2.5px solid #09c09e;border-top:2.5px solid #09c09e;border-radius:50%;animation:r1 2s linear infinite}@keyframes r1{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}

.Message{padding:0 15%;margin-bottom:50px;padding-top:50px;padding-bottom:50px;margin-top:-50px}.Message__title{text-align:center}.Message__title>p{margin:0 auto 50px auto;width:100px;border-bottom:2px solid}.Message__desc{text-align:center}@media screen and (max-width: 768px){.Message__desc{font-size:11px}}.Message__desc-main{font-weight:bold;font-size:20px}@media screen and (max-width: 768px){.Message__desc-main{font-size:15px}}

.Tech{background-color:#FFF;padding-top:50px;padding-bottom:50px;margin-top:-50px;margin-bottom:50px}.Tech__title{padding:0 15%;text-align:center}.Tech__title>p{margin:0 auto 50px auto;width:110px;border-bottom:2px solid}.Tech__desc{text-align:center}.Tech__logo-section{width:100%;text-align:center;display:flex;flex-flow:row-reverse}.Tech__logo-section__tech-img{width:50%}.Tech__logo-section-stack{width:50%}.Tech__logo-section-stack__img{width:90%;border-radius:5%;background-color:#FFF;display:flex;justify-content:center;padding:4% 0%}.Tech__logo-section-stack__img>img{width:50%;object-fit:cover}.Tech table{text-align:center;padding-left:15%}

.Achievements{width:100%;margin-bottom:50px;padding-bottom:50px;padding-top:50px;margin-top:-50px}.Achievements__title{padding:0 15%;text-align:center}.Achievements__title>p{margin:0 auto 50px auto;width:140px;border-bottom:2px solid}.Achievements__desc{padding:20px 15%;text-align:center}.Achievements__contents-section{padding:0 10%}.Achievements__contents-section__wrapper{display:grid;grid-template-columns:repeat(2, 1fr);grid-gap:30px;width:100%}@media screen and (max-width: 768px){.Achievements__contents-section__wrapper{grid-template-columns:repeat(1, 1fr)}}.Achievements__content-section__content{width:100%;border:1px solid}.Achievements__content-section__picture__img{width:100%}.Achievements__content-section__name{text-align:center;font-weight:bold}.Achievements__content-section__desc{text-align:center}@media screen and (max-width: 768px){.Achievements__content-section__picture__img{width:100%}}

.Info{background-color:#FFF;padding-top:50px;padding-bottom:50px;margin-top:-50px;margin-bottom:50px}.Info__title{text-align:center}.Info__title>p{margin:0 auto 50px auto;width:80px;border-bottom:2px solid}.Info__desc{text-align:center}.Info__members-section{padding:0 15%;display:flex}.Info__members-section__member{width:100%;padding:10px;margin:20px 0;background-color:#FFF}.Info__members-section__picture{text-align:center}.Info__members-section__picture>img{width:80%;border-radius:50%}.Info__members-section__job{font-weight:bold;border-bottom:2px solid #25285e}.Info__members-section__desc{font-size:12px}@media screen and (max-width: 768px){.Info__members-section{flex-direction:column}}.Info__contact-form{box-shadow:2px 2px 4px 1px rgba(0,0,0,0.248);padding-bottom:10px}.Info__contact-form__form{width:60%;margin:50px auto 0;text-align:left}@media screen and (max-width: 768px){.Info__contact-form__form{width:90%}}.Info__contact-form__section{margin-bottom:15px}.Info__contact-form__section>div>span{font-size:0.7rem;color:#bb1c59}.Info__contact-form__section__submit{text-align:center}.Info__contact-form__input{width:100%}@media screen and (max-width: 768px){.Info__contact-form__input{width:98%}}.Info__contact-form__submit{display:inline-block;border-radius:4px;font-size:1rem;text-align:center;cursor:pointer;padding:12px 45px;background:#1c4296;color:#ffffff;line-height:1em;transition:.3s;border:2px solid #1c4296}.Info__contact-form__submit:hover{color:#1c4296;background:#ffffff}.Info__contact-section{padding:0 15%;text-align:center}.Info__contact{display:flex;margin-top:50px}.Info__contact__tel>div{font-weight:bold}.Info__contact__mail>div{font-weight:bold}.Info__contact__line-img>img{width:50%}.Info__contact table{width:100%}@media screen and (max-width: 768px){.Info__contact{flex-direction:column}}

.Footer{text-align:center}

html{scroll-behavior:smooth}.App{overflow:hidden;width:100%;font-family:'Glegoo';color:#25285e;background-color:#e3eef0;display:flex;position:relative;flex-flow:row-reverse}.App a{text-decoration:none}.App a:link{color:#25285e}.App a:visited{color:#25285e}.App__navSection{width:20%;z-index:999}.App__contetentSection{width:80%}@media screen and (max-width: 768px){.App{display:block}.App__navSection{width:100%}.App__contetentSection{width:100%;padding-top:50px}}

