.Message{
  padding: 0 15%;
  margin-bottom: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: -50px;

  &__title{
    text-align: center;
    > p{
      margin: 0 auto 50px auto;
      width: 100px;
      border-bottom: 2px solid;
    }
  }

  &__desc{
    text-align: center;
    @media screen and (max-width: 768px){
      font-size: 11px;
    }
    &-main{
      font-weight: bold;
      font-size: 20px;
      @media screen and (max-width: 768px){
        font-size: 15px;
      }
    }
  }
}
