html{
  scroll-behavior: smooth;
}
.App{
  overflow:hidden;
  width: 100%;
  font-family: 'Glegoo';
  color: rgb(37, 40, 94);
  background-color: rgb(227, 238, 240);
  display: flex;
  position: relative;
  flex-flow: row-reverse;

  a{
    text-decoration: none;
    &:link{
      color: rgb(37, 40, 94);
    }
    &:visited{
      color: rgb(37, 40, 94);
    }

  }

  &__navSection{
   width: 20%; 
   z-index: 999;
  }

  &__contetentSection{
    width: 80%;
  }
  @media screen and (max-width: 768px){
    display: block;
      &__navSection{
        width: 100%; 
      }

      &__contetentSection{
        width: 100%;
        padding-top: 50px;
      }
  }
}
