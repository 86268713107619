.Info{
  background-color: #FFF;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: -50px;
  margin-bottom: 50px;
  &__title{
    text-align: center;
    > p{
      margin: 0 auto 50px auto;
      width: 80px;
      border-bottom: 2px solid;
    }
  }
  &__desc{
    text-align: center;
  }

  &__members-section{
    padding: 0 15%;
    display: flex;
    
    &__member{
      width: 100%;
      padding: 10px;
      margin: 20px 0;
      background-color: #FFF;
    }
    &__picture{
      text-align: center;
      >img{
        width: 80%;
        border-radius: 50%;
      }
    }
    &__job{
      font-weight: bold;
      border-bottom: 2px solid rgb(37, 40, 94);
    }
    &__desc{
      font-size: 12px;
    }
    @media screen and (max-width: 768px){
      flex-direction: column;
    }
  }

  &__contact-form{
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.248);
    padding-bottom: 10px;
    &__form{
      width: 60%;
      margin: 50px auto 0;
      text-align: left;
      @media screen and (max-width: 768px){
        width: 90%;
      }
    }

    &__section{
      margin-bottom: 15px;
      >div{
        >span{
          font-size: 0.7rem;
          color: rgb(187, 28, 89);
        }
      }
      &__submit{
        text-align: center;
      }
    }

    &__input{
      width: 100%;
      @media screen and (max-width: 768px){
        width: 98%;
      }
    }

    &__submit{
      display       : inline-block;
      border-radius : 4px;          /* 角丸       */
      font-size     : 1rem;        /* 文字サイズ */
      text-align    : center;      /* 文字位置   */
      cursor        : pointer;     /* カーソル   */
      padding       : 12px 45px;   /* 余白       */
      background    : #1c4296;     /* 背景色     */
      color         : #ffffff;     /* 文字色     */
      line-height   : 1em;         /* 1行の高さ  */
      transition    : .3s;         /* なめらか変化 */
      border        : 2px solid #1c4296;    /* 枠の指定 */
      &:hover{
        color         : #1c4296;     /* 背景色     */
        background    : #ffffff;     /* 文字色     */
      }
    }
  }

  &__contact-section{
    padding: 0 15%;
    text-align: center;
    
  }
  &__contact{
    display: flex;
    margin-top: 50px;

    &__tel{
      >div{
        font-weight: bold;
      }
    }
    &__mail{
      >div{
        font-weight: bold;
      }
    }
    &__line{
      &-img{
        >img{
          width: 50%;
        }
      }
    }
    table{
      width: 100%;
    }
    @media screen and (max-width: 768px){
      flex-direction: column;
    }
  }
}
