.Achievements{
  width: 100%;
  margin-bottom: 50px;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-top: -50px;
  &__title{
    padding: 0 15%;
    text-align: center;
    > p{
      margin: 0 auto 50px auto;
      width: 140px;
      border-bottom: 2px solid;
    }
  }
  &__desc{
    padding: 20px 15%;
    text-align: center;
  }
  &__contents-section{
    padding: 0 10%;
    
    &__wrapper{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
      width: 100%;
      @media screen and (max-width: 768px){
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
.Achievements__content-section{
  &__content{
    width: 100%;
    border: 1px solid;
  }
  &__picture{
    &__img{
      width: 100%;
    }
  }
  &__name{
    text-align: center;
    font-weight: bold;
  }
  &__desc{
    text-align: center;
  }
  @media screen and (max-width: 768px){
    &__picture{
      &__img{
        width: 100%;
      }
    }
  }
}
