.Sidenav{
  font-weight: bold;
  font-size: 18px;
  position: fixed;
  width: 100%;
  z-index: 90;
  // &-hamburger{
  //   display: none;
  // }
  &-container-sp{
    display: none;
  }
  @media screen and (max-width: 768px){
    &-container{
      display: none;
    }
    &-container-sp{
      display: initial;
      position: fixed;
      top: -100%;
      transition-duration: 500ms;
      width: 100%;
      background-color: #f3f3f3;
      &__text{
        border-bottom: 1px solid rgb(232, 232, 232);
      }
    }
    &-hamburger{
      &__button{
        position: fixed;
        display: flex;
        height: 50px;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: #b1ccda;
        z-index: 91;
        > span,
        > span:before,
        > span:after {
          content: '';
          display: block;
          height: 3px;
          width: 50px;
          border-radius: 3px;
          background-color: #ffffff;
          position: absolute;
        }
        > span:before {
            bottom: 8px;
        }
        > span:after {
            top: 8px;
        }
      }
    }
  }
}
.Sidenav-container{
  &__text{
    padding: 10px 10px; 
    cursor: pointer;
    &:hover{
      background-color: rgb(201, 201, 201);
    }
  }
}
.Sidenav-container-sp{
  &__text{
    padding: 10px 10px; 
    cursor: pointer;
    width: 100%;
    &:hover{
      background-color: rgb(201, 201, 201);
    }
  }
}

#menu-btn-check{
  display:none;
}
#menu-btn-check:checked ~ .Sidenav-hamburger__button span {
  background-color: rgba(255, 255, 255, 0);
}
#menu-btn-check:checked ~ .Sidenav-hamburger__button span::before {
  bottom: 0;
  transform: rotate(45deg);
  transition-property: all;
  transition-duration: 500ms;
}
#menu-btn-check:checked ~ .Sidenav-hamburger__button span::after {
  top: 0;
  transform: rotate(-45deg);
  transition-property: all;
  transition-duration: 500ms;
}

#menu-btn-check:checked ~ .Sidenav-container-sp {
  top: 50px;
}
